<template>
  <footer class="public-footer">
    <el-row type="flex" justify="space-around">
      <!--左侧-->
      <el-col :span="7">
        <div class="col-item">
          <img class="footer-logo" src="@/assets/footer/logo.png" />
          <p class="a-link-blue">
            <a :href="termsOfUseHref" target="_blank">{{ $t('User.Href1') }}</a>
          </p>
          <p class="a-link-blue">
            <a :href="privacyPolicyHref" target="_blank">{{  $t('User.Href2') }}</a>
          </p>
        </div>
      </el-col>
      <!--中间-->
      <el-col :span="7">
        <div class="col-item">
          <p class="title">{{ $t('Footer.Product.Tech') }}</p>
          <p class="a-link" @click="goToProject('bi')">SynEcoSys Database</p>
          <p class="a-link" @click="goToProject('celeviz')">CeleViz Browser</p>
          <p class="a-link" @click="goToProject('celelens')">CeleLens Cloud Platform</p>
        </div>
      </el-col>
      <!--右侧-->
      <el-col :span="10">
        <div class="col-item">
          <p class="title">{{ $t('Footer.Contact.Us') }}</p>
          <p class="a-link">
            {{ $t('Footer.Website') }}：
            <a target="_blank" href="https://singleron.bio/">https://singleron.bio/</a>
          </p>
          <singleron-contact-us-cn-comp v-if="isCN"/>
          <singlerom-contact-us-com-comp v-else/>
        </div>
      </el-col>
    </el-row>
    <div class="record-wrapper">
      <div v-if="isCN">
        <a class="record-link" target="_blank" href="https://beian.miit.gov.cn">沪ICP备2021028059号</a>
      </div>
      <div v-else>
        Copyright@2024 Singleron Biotechnologies.
      </div>
    </div>
  </footer>
</template>

<script>
import SingleronContactUsCnComp from "./components/ContactUsCn";
import SingleromContactUsComComp from "./components/ContactUsCom";
import { projectSite, termSite } from "@/const/footer";

export default {
  name: "SingleronFooterComp",
  components: {
    SingleronContactUsCnComp,
    SingleromContactUsComComp,
  },
  data() {
    return {
      val: "",
      site: '',
      isCN: true,
    };
  },
  props: {
    isChangeEmail: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    // this.site = getUrlLocation();
    this.$nextTick(() => {
      this.handleShowInfoByCountry();
    });
  },
  computed: {
    termsOfUseHref() {
      return this.isCN ? termSite['term']['CN'] : termSite['term']['other'];
    },
    privacyPolicyHref() {
      return this.isCN ? termSite['privacy']['CN'] : termSite['privacy']['other'];
    },
  },
  methods: {
    handleShowInfoByCountry() {
      this.isCN = process.env.VUE_APP_NODE_TEST !== 'awsproduction';
    },
    // 跳转其他网址
    goToProject(name) {
      const site = this.isCN ? 'cn' : 'com';
      window.open(projectSite[site][name],'_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.public-footer {
  width: 100%;
  background-color: #0b2766;
  //display: flex;
  //padding-top: 45px;
  .el-col {
    display: flex;
    justify-content: center;
    padding: 40px 20px 0 20px;
    .col-item {
      text-align: left;
      .footer-logo {
        width: 170px;
        height: 40px;
        margin-bottom: 25px;
      }
      .a-link-blue {
        line-height: 26px;
      }
      .a-link {
        line-height: 29px;
        cursor: pointer;
        font-weight: 500;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        opacity: 0.85;
        &:hover {
          color: #74c1FF
        }
        a {
          color: #74c1FF;
        }
      }
      .title {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 30px;
      }
      .link-icon {
        line-height: 30px;
        margin-top: 10px;
        img {
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
    &:last-child {
      .a-link {
        cursor: text;
        &:hover {
          color: #FFFFFF;
        }
      }
    }
  }
  .record-wrapper {
    height: 30px;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 30px;
    color: #fff;
    opacity: 0.6;
    font-size: 13px;
    a {
      margin-right: 10px;
      color: #fff;
      &:hover {
        color: #1ad7e5;
        text-decoration: underline;
      }
    }
  }
}
</style>
