export const userCN = {
    'User.Login.Sign.In': '账号登录',
    'User.Login.Username.Email': '用户名或邮箱',
    'User.Login.Password': '密码',
    'User.Login.Password.Tips': '密码不应少于6位',
    'User.Login.Sign.In2': '登录',
    'User.Login.Account': '还没有账号？',
    'User.Register.Sign.Up': '立即注册',
    'User.Register.Sign.Up1': '注册',
    'User.Forget.Password': '忘记密码？',
    'User.Email': '邮箱',
    'User.Code': '验证码',
    'User.GetAuth.Code': '获取验证码',
    'User.New.Password': '新密码',
    'User.New.Password.Again': '再次输入新密码',
    'User.Submit': '提交',
    'User.Back.To': '回到',
    'User.Loh in': '账号登录',
    'User.Create.Your.Account': '创建您的账号',
    'User.Username': '用户名',
    'User.First.Name': '名',
    'User.Last.Name': '姓',
    'User.Phone': '手机号',
    'User.Repeat.Password': '重复输入密码',
    'User.Institution.Name': '机构名称',
    'User.Research.Interest': '研究领域',
    'User.Code.Placeholder': '请输入邮件内的验证码',
    'User.Code.tips': '请输入邮件内的验证码。验证码有效期为5分钟。',
    'User.Get.Code': '获取验证码',
    'User.Agree.Check': '阅读并接受我们的 {link1} 和 {link2} ',
    'User.Agree.And': '和',
    'User.Href1': '使用条款',
    'User.Href2': '隐私政策',
    'User.Has.Account': '已经有账户了？',
    'User.Email.Tips': '请输入正确的邮箱地址',
    'User.New.Password.Tips': '密码必须6-16个字符',
    'User.New.Password.Again.Tips': '密码不一致',
    'User.Username.tips': '注册后，您将无法更改您的用户名。',
    'User.Register.Email.Tips': '请使用您的机构邮箱注册。',
    'User.Password.Tips': ' 必须至少包含一个大写字母和一个数字。',

    'User.Username.Length': '用户名长度必须3-50个字符!',
    'User.Username.Characters': '用户名不能包含特殊字符!',
    'User.Username.Starts': '用户名不能以数字开头!',
    'User.Username.No.Space': '用户名不能包含空格!',

    'Footer.Product.Tech': '产品技术',
    'Footer.Contact.Us': '联系我们',
    'Footer.Website': '网址',
    'Footer.Phone': '电话',
    'Footer.Email': '邮箱',
    'Footer.Address': '地址',
    'Footer.Address.Detail': '南京市江北新区药谷大道11号加速器二期06栋3-5层',
    'User.Feedback': '用户反馈',
    'User.Update.Log': '更新记录',

    'User.Content.AD.Info': 'I consent to receive product information and promotions from Singleron. I understand I can unsubscribe anytime using the link in the newsletters.',

};

export const userUS = {
    'User.Login.Sign.In': 'Sign in',
    'User.Login.Username.Email': 'Username or Email',
    'User.Login.Password': 'Password',
    'User.Login.Password.Tips': 'Password must be at least 6 digits',
    'User.Login.Sign.In2': 'Sign in',
    'User.Login.Account': 'Don\'t have an account?',
    'User.Register.Sign.Up': 'Sign up',
    'User.Register.Sign.Up1': 'Sign up',
    'User.Forget.Password': 'Forgot password?',
    'User.Email': 'Email',
    'User.Code': 'Code',
    'User.GetAuth.Code': 'Get Authentification code',
    'User.New.Password': 'New password',
    'User.New.Password.Again': 'New password again',
    'User.Submit': 'submit',
    'User.Back.To': 'Back to',
    'User.Loh in': 'log in',
    'User.Create.Your.Account': 'Create your account',
    'User.Username': 'Username',
    'User.First.Name': 'First name',
    'User.Last.Name': 'Last name',
    'User.Phone': 'Phone',
    'User.Repeat.Password': 'Repeat password',
    'User.Institution.Name': 'Institution Name',
    'User.Research.Interest': 'Research interest',
    'User.Code.Placeholder': 'Please enter email authentification code',
    'User.Code.tips': 'Please enter email authentification code. An authentication code is valid for 5 minutes.',
    'User.Get.Code': 'Get code',
    'User.Agree.Check': 'By clicking SIGN UP, you agree to your {link1} and {link2}',
    'User.Agree.And': 'and',
    'User.Href1': 'Terms of use',
    'User.Href2': 'Privacy policy',
    'User.Has.Account': 'Already have an account？',
    'User.Email.Tips': 'Please enter correct email address',
    'User.New.Password.Tips': 'Password must be 6 - 16 digits',
    'User.New.Password.Again.Tips': 'Passwords do not match',
    'User.Username.tips': 'You CANNOT change username once registered.',
    'User.Register.Email.Tips': 'Please register with organizational email.',
    'User.Password.Tips': 'At least one Uppercase letter，one number.',

    'User.Username.Length': 'Username must be at least 3 and no longer than 50 digits',
    'User.Username.Characters': 'Username cannot contain special characters!',
    'User.Username.Starts': 'Username cannot start with numbers!',
    'User.Username.No.Space': 'characters Username cannot contain space!',

    'Footer.Product.Tech': 'Products and Technology',
    'Footer.Contact.Us': 'Contact us',
    'Footer.Website': 'Website',
    'Footer.Phone': 'Phone',
    'Footer.Email': 'E-mail',
    'Footer.Address': 'Address',
    'Footer.Address.Detail': 'Building 06 , Accelerator Phase II ,<br> Yaogu Avenue11 , Nanjing , Jiangsu , China',
    'User.Feedback': 'Feedback',
    'User.Update.Log': 'Update log',
    'User.Content.AD.Info': 'I consent to receive product information and promotions from Singleron. I understand I can unsubscribe anytime using the link in the newsletters.',
};