import ServerConstants from './server.js';
import server from '@/server/request.js';
import { tpl_url } from '@/utils/common.js';


/**
 * 获取数据集详情
 * @param {*} stringId 数据集id
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getDatasetDetail(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.USER_DATABASE_API_DATESET_GET_DATASETS_DETAIL, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}



/**
 * 树形结构
 * @param {*} stringId 数据集id
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function userCelltypeHierarchyService2(stringId, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.API_GET_DATASET_DETAIL_CELLTYPE_HIERARCHY_BY_ID, stringId), function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}



/**
 * 获取数据改变数据集相关参数配置，获取分页total
 * @param {*} stringId 数据集id
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getUserEmbeddingAnnotationListService(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.DATABASE_API_USER_GET_EMBEDDING_ANNOTATION_LIST, stringId), function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * get方式 获取用户umap 不降点坐标数据类型，占比，数量
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getUserObservationAnnotationViewDetailService(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.DATABASE_API_USER_GET_OBSERVATION_ANNOTATION_VIEW2_DETAIL, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 过滤group分组接口
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getFilterOptions(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.USER_DATABASE_API_GET_DIFFERENT_EXPRESSION, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * 查询用户数据集关系
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getUserDatasetRelation(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.USER_DATABASE_API_GET_RELATION, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}




/**
 * 用户数据集umap --- 坐标
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function userEmbeddingService(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(tpl_url(ServerConstants.USER_DATABASE_API_DATESET_GET_EMBEDDING, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 用户数据集umap --- 着色 --- post
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function userObservationAnnotationService(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(tpl_url(ServerConstants.USER_DATABASE_API_DATESET_GET_OR_POST_OBSERVATION_ANNOTATIION, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 用户数据集umap --- 着色 --- get
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getUserObservationAnnotationService(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.USER_DATABASE_API_DATESET_GET_OR_POST_OBSERVATION_ANNOTATIION, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * get方式 获取用户umap 不降低点坐标数据, 坐标范围
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getUserEmbeddingView2DetailService(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.DATABASE_API_USER_GET_EMBEDDING_VIEW2_DETAIL, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * user observation_gene
 * **/
export function userObservationGeneService(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postForm(tpl_url(ServerConstants.USER_DATABASE_API_DATESET_GET_OBSERVATION_GENE, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 获取subset列表
 * @param {*} stringId 数据集id
 * @param {*} success 
 * @param {*} errorfun 
 * @returns 
 */
export function getSubsetList(stringId, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.API_GET_OBSERVATION_SUBSET_BY_ID, stringId), function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 复制subset列类型
 * @param {*} stringId 
 * @param {*} params 
 * @param {*} success 
 * @param {*} errorfun 
 * @returns 
 */
export function copySubsetType(stringId, params, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(tpl_url(ServerConstants.API_POST_COPY_OBSERVATION_SUBSET_BY_ID, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 删除subset列类型
 * @param {*} stringId 
 * @param {*} params 
 * @param {*} success 
 * @param {*} errorfun 
 * @returns 
 */
export function deleteSubsetType(stringId, params, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.deleteJSON(tpl_url(ServerConstants.API_DELETE_OBSERVATION_SUBSET_BY_ID, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * 编辑subset列类型
 * @param {*} stringId 
 * @param {*} params 
 * @param {*} success 
 * @param {*} errorfun 
 * @returns 
 */
export function editSubsetType(stringId, params, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(tpl_url(ServerConstants.API_POST_EDIT_OBSERVATION_SUBSET_BY_ID, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 合并subset列类型
 * @param {*} stringId 
 * @param {*} params 
 * @param {*} success 
 * @param {*} errorfun 
 * @returns 
 */
export function mergeSubsetType(stringId, params, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(tpl_url(ServerConstants.API_POST_MERGE_OBSERVATION_SUBSET_BY_ID, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 检查子集是否有相关子集
 * @param {*} stringId 
 * @param {*} success 
 * @param {*} errorfun 
 * @returns 
 */
export function checkRelevantSubset(stringId, params, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.API_GET_CHECKOUT_RELEVANT_SUBSET, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 计算差异基因
 * @param {*} stringId 
 * @param {*} params 
 * @param {*} success 
 * @param {*} errorfun 
 * @returns 
 */
export function calculateDegsByID(stringId, params, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(tpl_url(ServerConstants.API_POST_CALCULATE_DEGS_TASK_BY_ID, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}


/**
 * map cell type
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function mapCellType(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.postJSON(tpl_url(ServerConstants.API_POST_MAP_CELL_TYPE, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}

/**
 * 查询用户数据集可映射关系 dataset_mapping_relation
 * @param {*} stringId
 * @param {*} params
 * @param {*} success
 * @param {*} errorfun
 * @returns
 */
export function getDatasetRelation(stringId, params = {}, success, errorfun) {
    return new Promise((resolve, reject) => {
        server.getJSON(tpl_url(ServerConstants.API_GET_DATASET_MAPPING_RELATION, stringId), params, function(response) {
            resolve(response.data);
            server.call(success, response);
        }, function(error) {
            server.call(errorfun, error);
            reject(error);
        });
    });
}