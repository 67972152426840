export const cnvCN = {
    'Cnv.Explore.Title': '拷贝数变化和肿瘤异质性',

    //cnv
    'Cnv.Select.Sample': '样本:',
    'Cnv.Select.Cells.To.test.CNV': '需要检测CNV的细胞:',
    'Cnv.Select.Select.Normal.Cells.As.Reference': '选择正常细胞作为参考:',
    'Cnv.Result.Detected.CNV': '检测到的CNV ',
    'Cnv.Result.Detected.CNV.Tips': '热图可视化了细胞中检测到的拷贝数变异。基因沿染色体从左到右排序，红色代表扩增，蓝色代表缺失。',
    'Cnv.Result.CNV.Score': 'CNV 分数',
    'Cnv.Result.CNV.Score.Tips': 'CNV分数量化了拷贝数变异的水平。分数越高表示检测到的拷贝数变异水平越大。',
    'Cnv.Download.CNS.Score.Table': '下载CNV分数结果',

    //ITH
    'ITH.Compare.ITH.By': '比较ITH',
    'ITH.Cell.Type': '细胞类型',
    'ITH.Sample.Intra.Tumoral.Heterogeneity': '样本肿瘤异质性',
    'ITH.Sample.Intra.Tumoral.Heterogeneity.Tips': 'ITH分数描述了每个选定样本中肿瘤的瘤内异质性，使用了新格元开发的算法。',
};

export const cnvUS = {
    'Cnv.Explore.Title': 'Explore CNV and malignancy',

    //cnv
    'Cnv.Select.Sample': 'Sample:',
    'Cnv.Select.Cells.To.test.CNV': 'Cells to test CNV:',
    'Cnv.Select.Select.Normal.Cells.As.Reference': 'Select normal cells as reference:',
    'Cnv.Result.Detected.CNV': 'Detected CNV ',
    'Cnv.Result.Detected.CNV.Tips': 'The heatmap visualizes detected CNV in the cells. Genes are ranked from left to right along the chromosome. Red indicates amplification. Blue indicates deletion.',
    'Cnv.Result.CNV.Score': 'CNV score',
    'Cnv.Result.CNV.Score.Tips': 'the score quantifies the level of CNV. The higher score indicates the greater level of CNV detected.',
    'Cnv.Download.CNS.Score.Table': 'Download CNV score table',

    //ITH
    'ITH.Compare.ITH.By': 'Compare ITH by',
    'ITH.Cell.Type': 'Cell type',
    'ITH.Sample.Intra.Tumoral.Heterogeneity': 'Sample Intra-tumoral heterogeneity',
    'ITH.Sample.Intra.Tumoral.Heterogeneity.Tips': 'The ITH score describes the intra-tumoral heterogeneity of tumors from each selected sample using an algorithm developed by Singleron.',
};