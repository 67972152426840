export const cellAnnotationUS = {
    'Annotation.Title': 'Explore Cell Annotations',
    'Annotation.Module.Title1': 'Major cell type annotations',
    'Annotation.Module.Title2': 'Cell subtype annotations',
    'Annotation.Button1': 'View latest annotation result in table',
    'Annotation.Button2': 'Accept and apply',
    'Annotation.Button3': 'Revert adjustments',
    'Annotation.Button4': 'Select cells to perform subclustering',
    'Annotation.Button4.Tip': 'The cell counts of the subclustered cluster should be greater than or equal to 100.',
    'Annotation.Button.Return': 'Return',
    'Annotation.Button.Edit': 'Edit',
    'Annotation.Button.More': 'MORE',

    // 'Annotation.Tip': 'Cortal A, Martignetti L, Six E, Rausell A (2020). “Cell-ID: gene signature extraction and cell identity recognition at individual cell level.” bioRxiv. doi: 10.1101/2020.07.23.215525.',
    'Annotation.Tip1': 'By default we do not recommend batch effect removal for cancer samples to prevent removal of the intra-tumoral heterogeneity.',

    // 'Annatation.Sub.Title': 'Subclustering',
    'Annatation.Sub.Title1': 'Job name',
    'Annatation.Sub.Title2': 'Select',
    'Annatation.Sub.Title3': 'Cell filtering options',
    //'Annatation.Sub.Title4': 'Feature plot of',

    'Annotation.Subclustering.Type': 'Subclustering with annotation',
    'Annotation.Subclustering.Type1': 'Subclustering only',
    'Annotation.Subclustering.Tip': 'Subtypes will not be annotated',
    'Annotation.Subclustering.Type2': 'Include inferred clusters',

    'Annotation.Subclustering.Tip3': 'Currently, automatic annotation is limited to subtypes of Mononuclear phagocytes, T cells, B cells, and Endothelial cells in human and mice. The cell names must be consistent for proper usage; for example, it is necessary to use "T cells," while variations like "T cell," "Tcell," or "t cells" may lead to the system being unable to recognize valid cell types.',
    'Annotation.Subclustering.Tip4': 'No matching cell types',

    // 'Annotation.Placeholder': 'Job name + “- sub immune”',
    'Annotation.Placeholder1': 'Type in annotation',

    'Annotation.table.Cluster.Id': 'Cluster id',
    'Annotation.table.Markers': 'Markers',
    'Annotation.table.Cell.Type': 'Cell type',
    'Annotation.table.Best.Match': 'Cell type best match',
    'Annotation.table.Alternative.Matches': 'Cell type alternative matches',
    'Annotation.table.Custom.Annotation': 'Add custom annotation',
    'Annotation.table.Cell.Type.Annotation': 'Cell type annotation',

    'Annotation.Choose.Algorithm': 'Algorithm',
    'Annotation.Choose.Reference': 'Reference',
    'Annotations.Processing.Tips': 'Annotation labels are processing. Please refresh the page later if new labels are not loaded automatically',
    'Annotations.Error.Tips': 'Annotation labels are processing. Please refresh the page later if new labels are not loaded automatically',

    'Annotations.Marker.Not.Expressed': 'marker not expressed in dataset',

    // new add
    'Annotations.Params.Samples': 'Samples*',
    'Annotations.Params.Number.Of.Highly.Variable.Genes': 'Number of highly variable genes',
    'Annotations.Params.Batch.Effect.Removal': 'Batch effect removal',
    'Annotations.Params.Method': 'Method',
    'Annotations.Params.Source': 'Source',
    'Annotation.Params.Cell.Type.Reference': 'Cell type reference*',

    'Annotations.Ref.Subset.Tips': 'Raw cluster, Major cell types and Cell subtypes groups cannot directly undergo subclustering and extraction. If subclustering or extraction is required, these subsets should be {copied} to a new group before proceeding.',
    'Annotation.Copied': 'copied',
    'Annotations.Subtype.Annotations.Title': 'Cell subtype annotations/Cell extraction',
    'Annotations.Subtype.Annotations.Title2': 'Cell extraction',
    'Annotations.Subtype.Annotations.Btn.Title': 'Select cells to perform subclustering/extraction',
    'Annotations.Subtype.Annotations.Btn.Title2': 'Select cells to perform extraction',
    'Annotations.Tab.Extract': 'Extraction',
    'Annotation.Extract.Cluster.Tip': 'Cells will retain the cell annotation labels',
    'Annotation.Extract.Re.Dimensionality.Reduction': 'Re-dimensionality reduction',
};

export const cellAnnotationCN = {
    // new add
    'Annotations.Params.Samples': '样本*',
    'Annotations.Params.Number.Of.Highly.Variable.Genes': '高可变基因数量',
    'Annotations.Params.Batch.Effect.Removal': '批次效应移除',
    'Annotations.Params.Method': '方法',
    'Annotations.Params.Source': '来源',
    'Annotation.Params.Cell.Type.Reference': '细胞类型参考*',

    'Annotation.Title': ' 细胞注释',
    'Annotation.Module.Title1': '细胞大类注释',
    'Annotation.Module.Title2': '细胞亚型注释',
    'Annotation.Button1': '查看历史注释结果',
    'Annotation.Button2': '同意并应用',
    'Annotation.Button3': '恢复调整',
    'Annotation.Button4': '选择需要subclustering的细胞组',
    'Annotation.Button4.Tip': '需要细分的簇细胞数需大于等于100',
    'Annotation.Button.Return': '返回',
    'Annotation.Button.Edit': '编辑',
    'Annotation.Button.More': '更多',

    // 不翻译 'Annotation.Tip': 'Cortal A, Martignetti L, Six E, Rausell A (2020). “Cell-ID: gene signature extraction and cell identity recognition at individual cell level.” bioRxiv. doi: 10.1101/2020.07.23.215525.',
    'Annotation.Tip1': '默认情况下，我们不建议去除癌症样本的批次效应，以防止去除肿瘤内部的异质性。',

    // 'Annatation.Sub.Title': 'Subclustering',
    'Annatation.Sub.Title1': '任务名称',
    'Annatation.Sub.Title2': '选择',
    'Annatation.Sub.Title3': '细胞过滤选项',
    //'Annatation.Sub.Title4': 'Feature plot of',

    'Annotation.Subclustering.Type': '自动注释',
    'Annotation.Subclustering.Type1': '机械分群',
    'Annotation.Subclustering.Tip': '细胞类型不会被注释',
    'Annotation.Subclustering.Type2': '包含推断的注释结果',

    'Annotation.Subclustering.Tip3': '目前仅限人和小鼠的Mononuclear phagocytes、T cells、B cells和Endothelial cells的亚型自动注释。细胞名称需要完全保持一致才可使用，例如必须使用T cells，T cell或Tcell或t cells可能会导致系统无法识别有效细胞类型。',
    'Annotation.Subclustering.Tip4': '无匹配细胞类型',

    // 'Annotation.Placeholder': 'Job name + “- sub immune”',
    'Annotation.Placeholder1': '输入注释结果',

    'Annotation.table.Cluster.Id': 'Cluster id',
    'Annotation.table.Markers': 'Markers',
    // 'Annotation.table.Cell.Type': '细胞类型',
    'Annotation.table.Best.Match': '最佳匹配细胞类型',
    'Annotation.table.Alternative.Matches': '其他匹配细胞类型',
    'Annotation.table.Custom.Annotation': '添加我的注释',
    'Annotation.table.Cell.Type.Annotation': '细胞类型注释',

    'Annotation.Choose.Algorithm': '注释算法',
    'Annotation.Choose.Reference': '注释参考',
    'Annotations.Processing.Tips': '注释结果正在同步中。如果长时间未自动同步新标签，请尝试刷新页面。',
    'Annotations.Error.Tips': '注释标签加载失败。请重试。',

    'Annotations.Marker.Not.Expressed': 'marker not expressed in dataset',

    'Annotations.Ref.Subset.Tips': 'Raw cluster、Major cell types和Cell subtypes分组无法直接做亚型注释和提取细胞。若需亚型注释或提取细胞，可将该分组{copied}至新分组后操作。',
    'Annotation.Copied': '复制',


    'Annotations.Subtype.Annotations.Title': '细胞亚型注释/提取细胞',
    'Annotations.Subtype.Annotations.Title2': '提取细胞',
    'Annotations.Subtype.Annotations.Btn.Title': '选择需要聚类/提取的细胞',
    'Annotations.Subtype.Annotations.Btn.Title2': '选择需要提取的细胞',
    'Annotations.Tab.Extract': '提取细胞',
    'Annotation.Extract.Cluster.Tip': '细胞保留已有注释标签',
    'Annotation.Extract.Re.Dimensionality.Reduction': '重新降维',

};
