export const buttonTextCN = {
    'Button.Text.Report': '报告',
    'Button.Text.Parse': '解析',
    'Button.Text.Parsed': '已解析',
    'Button.Text.Parsing': '解析中',
    'Button.Text.View': '查看',
    'Button.Text.Delete': '删除',
    'Button.Text.Confirm': '确认',
    'Button.Text.Cancel': '关闭',
    'Button.Text.Favorite': '收藏',
    'Button.Text.Delete.All.Selected': '删除选中所有',
    'Button.Text.View.Edit.Info': '查看或编辑信息',
    'Button.Text.View.Results': '查看结果',
    'Button.Text.View.Job.Info': '查看任务信息',
    'Button.Text.Resume': '继续',
    'Button.Text.Suspend.Analysis': '暂停分析',
    'Button.Text.Re.Edit.Job': '重新编辑任务',
    'Button.Text.Contact.Us': '联系我们',

    'Button.Text.Customize': '自定义',
    'Button.Text.Save': '保存',
    'Button.Text.Merge': '合并',
    'Button.Text.Run': '提交',
    'Button.Text.Clear': '清除',
    'Button.Text.Add': '添加',

    'Button.Text.Edit': '编辑',
    'Button.Text.Edit.Species': '编辑物种',
    'Button.Text.Select': '选择',
    'Button.Text.Ok': 'OK',
    'Button.Text.Clear.All': '清除所有',
    'Button.Text.Search': '搜索',
    'Button.Text.Remove': '移除',
    'Button.Text.Recalculate': '重新计算',
    'Button.Text.Add.To.Gene.List': '添加到基因列表',
    'Button.Text.View,Gene.Expression': '查看表达',
    'Button.Text.View.Pathway.Enrichment': '查看通路富集分析',

    'Button.Text.Bar.Plot': 'Barplot',
    'Button.Text.Dot.Plot': 'Dot plot',

    'Button.text.Add': '添加',

    'Button.Text.My.Analysis': '我的分析',

    'Button.Text.Plot': '提交', // Plot
    'Button.Text.Upload': '上传', // Plot
    'Button.Text.View.Expression.Changes': '查看表达',

    'Button.Text.Next': '下一步',

    'Button.Text.Disagree': '不同意',
    'Button.Text.Agree': '同意',
    'Button.Submit.Subscription.Request': '提交订阅申请',
    'Button.Upload.Now': 'Upload now',

    'Button.Example.Data': '示例数据',

    'Button.Text.Copy': '复制',
    'Button.Text.Calculate': '计算',
    'Button.Text.Download': '下载',
    'Button.Text.SynEcoSys.Database': 'SynEcoSys数据库',
    'Button.Text.Database.Subscription': '数据浏览权限',
    'Button.Text.H5ad.To.Rds': ' H5AD转为RDS',
    'Button.Text.Map': '映射',
};
export const buttonTextUS = {
    'Button.Text.Report': 'Report',
    'Button.Text.Parse': 'Parse',
    'Button.Text.Parsed': 'Parsed',
    'Button.Text.Parsing': 'Parsing',
    'Button.Text.View': 'View',
    'Button.Text.Delete': 'Delete',
    'Button.Text.Confirm': 'Confirm',
    'Button.Text.Cancel': 'CANCEL',
    'Button.Text.Favorite': 'Favorite',
    'Button.Text.Delete.All.Selected': 'Delete all selected',
    'Button.Text.View.Edit.Info': 'View or edit info',
    'Button.Text.View.Results': 'View results',
    'Button.Text.View.Job.Info': 'View job info',
    'Button.Text.Resume': 'Resume',
    'Button.Text.Suspend.Analysis': 'Suspend analysis',
    'Button.Text.Re.Edit.Job': 'Re-edit job',
    'Button.Text.Contact.Us': 'Contact us',

    'Button.Text.Customize': 'Customize',
    'Button.Text.Save': 'Save',
    'Button.Text.Run': 'RUN',
    'Button.Text.Merge': 'Merge',
    'Button.Text.Clear': 'Clear',
    'Button.Text.Add': 'add',
    'Button.Text.Edit': 'Edit',
    'Button.Text.Edit.Species': 'Edit species',
    'Button.Text.Select': 'Select',
    'Button.Text.Ok': 'Ok',
    'Button.Text.Clear.All': 'Clear All',
    'Button.Text.Search': 'Search',
    'Button.Text.Remove': 'Remove',
    'Button.Text.Recalculate': 'Recalculate',
    'Button.Text.Add.To.Gene.List': 'Add to gene list',
    'Button.Text.View,Gene.Expression': 'View gene expression',
    'Button.Text.View.Pathway.Enrichment': 'View pathway enrichment',

    'Button.Text.Bar.Plot': 'Barplot',
    'Button.Text.Dot.Plot': 'Dot plot',

    'Button.text.Add': 'Add',

    'Button.Text.My.Analysis': 'My analysis',

    'Button.Text.Plot': 'Plot',
    'Button.Text.Upload': 'Upload',
    'Button.Text.View.Expression.Changes': 'View expression changes',


    'Button.Text.Next': 'Next',

    'Button.Text.Disagree': 'Disagree',
    'Button.Text.Agree': 'Agree',
    'Button.Submit.Subscription.Request': 'Submit subscription request',
    'Button.Upload.Now': 'Upload now',
    'Button.Example.Data': 'Example data',

    'Button.Text.Copy': 'Copy',
    'Button.Text.Calculate': 'Calculate',
    'Button.Text.Download': 'Download',

    'Button.Text.SynEcoSys.Database': 'SynEcoSys Database',
    'Button.Text.Database.Subscription': 'Database subscription',
    'Button.Text.H5ad.To.Rds': ' H5AD to RDS',
    'Button.Text.Map': 'Map',
};