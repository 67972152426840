export const URL_PREFIX = "/database-api/celeLens";
export const SESSION_TYPE = {
    TOKEN: 'token',
    USERUUID: 'useruuid',
    LOGINSTATUS: 'loginOut',
};
export const Expression_Layer_NUM = 'normalised';
export const catchRouters = [
    'SingleronViewData', 'SingleronGeneExpression',
    'SingleronCellularComposition', 'SingleronDifferentialExpression',
    'SingleronCellQualityQC', 'SingleronPathwayEnrichment', 'SingleronCelltypeRelationship',
];
// cell type 别名
export const ALL_CELL_TYPE_FORMAT_LABEL = {
    annot_full: 'major cell types',
    annot_sub: 'cell subtypes',
    annot_sub2: 'cell subtypes 2',
    annot_article: 'original article cluster',
    annot_auto: 'Automatic',
    cluster: 'raw_cluster',
};

// 物种分类
export const species = {
    options: [{
        value: 'Homo sapiens',
        label: 'Homo sapiens',
    }, {
        value: 'Mus musculus',
        label: 'Mus musculus',
    }],
};
export const PopoverTips = {
    significantDGETip: 'The significant DEGs with adjusted p value ≤ 0.05 are filtered for genes with log transformed fold change > 0.25 and expression in more than 10% of the cells.',
    top10Tip: 'DEGs with log transformed fold change > 1, expressed in more than 10% of the cells, and with adjusted p value <= 0.05 are considered significant. The significant DEGs after filtering are piped into pathway enrichment analysis using clusterProfiler(version 4.2.0) and reference databases of Gene Ontology, Reactome and Wikipathway.',
    layerTip: 'Raw counts is subjected to the deviation of actual sequencing depth of individual cells. Normalized counts gives more reliable results for comparing the expression of multiple genes.',
    hightlightAndShowTip: 'Check box to highlight cells. Click on blue square icon to hide cells. Click on arrow to collapse the information',
    uploadInfo: 'Gene signature files must be .csv or .txt format. Each line should contain a single gene.',
    compareGroupAverage: 'If the selected group has less than 3 samples, the box plot cannot be plotted',
    umapCheckBoxTip: 'Check box to highlight cells. Click on blue square icon to hide cells. Click on arrow to collapse the information',
    geneHeatmapTip: 'Blue color suggests cells do not exist.',
    geneViolinTip: 'Too few cells result in the violin shape looking like a horizontal line.',
};
export const Each_Plot_Legend_Titles = {
    geneExpressionColorBar: 'Average<br>Expression',
    geneExpressionSizeLegend: 'Percent Expressed',
    GeneExpression: 'Average Expression',
    geneExpressionD3ColorBar: 'Average<br>Expression',
    geneExpressionLayer: 'Genes <br>expression',
    geneExpressionNormalized: 'Genes expression (normalized)',
    cellAnnotationSize: 'Fraction of cells <br> in group (%)',
    cellAnnotationColor: 'Mean expression<br>in group',
};

export const layerInfo = {
    raw: 'raw',
    normalised: 'normalised',
};

export const PUBLIC_CONST_PARAMS = {
    annotfull: 'cluster',
    annotSub2: 'annot_sub2',
    annotSub: 'annot_sub',
    annotArticle: 'annot_article',
    seuratClusters: 'seurat_clusters',
    rawCluster: 'raw_cluster',
    removeArray: ['Species', 'orig.ident', 'Days since diagnosed', 'If sorted'],
    removeArray1: ['annot_full', 'annot_sub', 'annot_sub2', 'Sample ID'],
    annot_cell: ['annot_full', 'annot_sub', 'annot_sub2', 'cluster'], // 不要添加其他的
    sample: ['Sample ID', 'sample'],
    removeDoublet: [],
};

export const DEG_CELL_TYPE_TO_ACTUAL_CELL_TYPE_NAME = {
    rank_genes_groups_cluster: 'cluster',
    rank_genes_groups: 'cluster',
    rank_genes_groups_annot_full: 'annot_full',
    rank_genes_groups_annot_sub: 'annot_sub',
    rank_genes_groups_annot_sub2: 'annot_sub2',
    rank_genes_groups_raw_cluster: 'raw_cluster',
};

export const removeColorMethods = {
    toRemoveList3: ['annot_full', 'Sample ID', 'annot_sub', 'annot_sub2', 'Stage', 'annot_article', 'seurat_clusters', 'cluster'],
    toRemoveList2: ['annot_full', 'annot_sub', 'annot_sub2', 'annot_article', 'seurat_clusters', 'cluster', 'raw_cluster', 'annot_modified'],
    toRemoveList4: ['annot_full', 'annot_sub', 'annot_sub2', 'annot_article', 'seurat_clusters', 'raw_cluster',
      'Species', 'orig.ident', 'Days since diagnosed', 'If sorted', 'cluster', 'annot_modified', 'louvain', 'celltype',
        'n_', 'pct_', 'total_',
    ],
    roRemoveList5: ['annot_full', 'annot_sub', 'annot_sub2', 'cluster', 'annot_article', 'seurat_clusters'],
    toRemoveCellType: ['unassigned', 'unknown'],
    allCellsType: ['annot_full', 'annot_sub', 'annot_sub2', 'cluster', 'annot_article', 'seurat_clusters'],
    rank_genes_groups_cluster: ['raw_cluster', 'rank_genes_groups'],
    removeLabelKey: ["annot_full", "annot_sub", "annot_sub2", "annot_article", "seurat_clusters", "cluster", "celltype"],
    standardCellTypes: ['annot_full', 'annot_sub', 'annot_sub2', 'annot_auto'],
};
export const specialString = {
    someSpecialString: [' ',',', ':', ';', '+', '-', '_'],
    splitText: 'spaceText',
};
// differential expression deg表头
export const RankGeneTableHeader = {
    newAdd: ['mean', 'mean_raw'],
    oldTableHeader: ['cluster', 'GeneID', 'mean',  'LogFC', 'Pct1', 'score', 'scores', 'PvalueAdj', 'Pct2', 'Pvalue'],
    newTableHeader: ['cluster', 'Gene id', 'Average expression (normalized)',  'LogFC', 'Pct1', 'Z score', 'z score', 'P_val_adj', 'Pct2', 'Pvalue'],
};

export const includeMenu = {
    menuList: ["Homepage", 'Browse By', "Tissue atlas", "Genes", "Cells", "Study", 'Statistics'],
};

export const USER_CELL_TYPE = {
    annotModified: 'annot_full',
    annotModified1: 'annot_sub',
    cluster: 'cluster',
};

export const dataAndAnalysis = {
    allIncludeCellType: ['annot_full', 'annot_sub', "annot_sub2", 'annot_article', 'seurat_clusters', 'cluster'],
    cellOptions: ['all', 'immune'],
    cellTypeOption: ['annot_full', 'annot_sub', 'annot_sub2', 'cluster'],
    sampleIDParams: ['Sample ID', 'buttonReject1', 'buttonReject', 'buttonReject2'],
    annotArticleParams: ['annot_article', 'buttonReject', 'buttonReject1', 'buttonReject2'],
    seuratClustersParams: ['seurat_clusters', 'buttonReject', 'buttonReject1', 'buttonReject2'],
    annotAutoParams: ['annot_auto', 'buttonReject', 'buttonReject1', 'buttonReject2'],
};

export const selectFromCytokine = {
    selectFromCytokine: ['Chemokines', 'Epidermal growth factor(EGF)', 'Vascular endothelial growth factor(VEGF)', 'Insulin-like growth factor(IGF)',
        'Platelet-derived growth factor (PDGF)', 'Colony-stimulating factor(CSF)', 'Fibroblast growth factor(FGF)', 'Tumor necrosis factor(TNF)',
        'Transforming growth factor(TGF)',
    ],
    optTables: ['/opt/tables/Chemokines.xls', '/opt/tables/EGF.xls', '/opt/tables/VEGF.xls', '/opt/tables/IGF.xls',
        '/opt/tables/PDGF.xls', '/opt/tables/CSF.xls', '/opt/tables/FGF.xls', '/opt/tables/TNF.xls',
        '/opt/tables/TGF.xls',
    ],
    // 'Chemokines': '/opt/tables/Chemokines.xls',
    // 'Epidermal growth factor(EGF)': '/opt/tables/EGF.xls',
    // 'Vascular endothelial growth factor(VEGF)': '/opt/tables/VEGF.xls',
    // 'Insulin-like growth factor(IGF)': '/opt/tables/IGF.xls',
    // 'Platelet-derived growth factor (PDGF)': '/opt/tables/PDGF.xls',
    // 'Colony-stimulating factor(CSF)': '/opt/tables/CSF.xls',
    // 'Fibroblast growth factor(FGF)': '/opt/tables/FGF.xls',
    // 'Tumor necrosis factor(TNF)': '/opt/tables/TNF.xls',
    // 'Transforming growth factor(TGF)': '/opt/tables/TGF.xls',
};

// 细胞互作图物种白名单
export const cellphoneDBWhiteSpecies = [
    'Macaca mulatta',
    'Mus musculus',
    'Homo sapiens',
];

// moncle2参数
export const moncleParams = {
    find_hvg_method: [{
        label: 'seurat',
        value: 'seurat',
    },{
        label: 'persion',
        value: 'persion',
    }],
};