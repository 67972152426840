export const degsTableCN = {
    'Degs.Table.List.Of.Cluster.Degs': '差异表达基因',
    'Degs.Table.Choose': '选择子集',
    'Degs.Table.File.Download': 'DEG file download',
    'Degs.Table.Cluster': 'cluster',
    'Degs.Table.Gene.ID': 'Gene id',
    'Degs.Table.P.Value': 'Pvalue',
    'Dega.Table.Average.Expression.Nnormalized': '平均表达量',
    'Degs.Table.Average.Expression.Rraw': '平均表达量（raw）',
    'Degs.Table.LogFC': 'LogFC',
    'Degs.Table.Pct1': 'Pct1',
    'Degs.Table.Z.Score': 'z score',
    'Degs.Table.Z.Score1': 'Z score',
    'Degs.Table.P.Val.Adj': 'Adjusted p value',
    'Degs.Table.Pct2': 'Pct2',
    'Deg.Table.Tips': '一个cluster的差异基因分析只有在存在三个或更多细胞的情况下才能进行',
    'Deg.Table.Btn.Table': '表格',
    'Deg.Table.Btn.Heatmap': '均值热图',
    'Deg.Table.Btn.Expression.Heatmap': '热图',

};

export const degsTableUS = {
    'Degs.Table.List.Of.Cluster.Degs': 'Differential Expression Genes',
    'Degs.Table.Choose': 'Choose subset',
    'Degs.Table.File.Download': 'DEG file download',
    'Degs.Table.Cluster': 'cluster',
    'Degs.Table.Gene.ID': 'Gene id',
    'Degs.Table.P.Value': 'Pvalue',
    'Dega.Table.Average.Expression.Nnormalized': 'Average expression (normalized)',
    'Degs.Table.Average.Expression.Rraw': 'Average expression (raw)',
    'Degs.Table.LogFC': 'LogFC',
    'Degs.Table.Pct1': 'Pct1',
    'Degs.Table.Z.Score': 'z score',
    'Degs.Table.Z.Score1': 'Z score',
    'Degs.Table.P.Val.Adj': 'Adjusted p value',
    'Degs.Table.Pct2': 'Pct2',
    'Deg.Table.Tips': 'Differential gene analysis for a cluster can only be performed when there are 3 cells or more.',
    'Deg.Table.Btn.Table': 'Table',
    'Deg.Table.Btn.Heatmap': 'Average expression Heatmap',
    'Deg.Table.Btn.Expression.Heatmap': 'Expression heatmap',

};