const changeColors = {
    namespaced: true,
    // 声明状态
    state: {
        currentColorByColors: {},
        colorByMethodToChangeColors: {},
    },
    // 更新状态
    mutations: {
        changeColorByUser(state, data) {
            state.currentColorByColors = JSON.parse(JSON.stringify(data['current']));
            state.colorByMethodToChangeColors = JSON.parse(JSON.stringify(data['all']));
        },
    },
    // 给actions注册一个事件处理函数，当这个函数触发时，将状态提交到mutations中处理
    actions: {
        changeColorByUserFunction({commit}, data) {
            commit('changeColorByUser', data); // 提交到mutations处理
        },
    },
};
export default changeColors;