export const analysisInfoCN = {
    'Analysis.Tab.Name': '任务信息',
    'Analysis.Analysis.ID': 'Analysis ID',
    'Analysis.Cells': 'Cells',
    'Analysis.Samples': 'Samples',

    'Analysis.Sample.Info': '样本信息', // 测试用
    'Analysis.Sample.Counts': '样本数量',
    'Analysis.Sample.Names': '样本名字',
    'Analysis.Species': 'Species',
    'Analysis.Tissue': '组织',
    'Analysis.Sample.Diseases': 'Sample diseases',

    'Analysis.Cell.Filtering.Info': 'Cell filtering info',
    'Analysis.Expressed.Gene.Range': '表达基因范围',
    'Analysis.Expressed.UMI.Range': '表达UMI范围',
    'Analysis.Mitochondrial.Content.Below': 'Mitochondrial content below(percentage)',

    'Analysis.Cell.Quality.Adjustments': 'Cell quality adjustments',
    'Analysis.Contamination.Removal': 'Contamination removal',
    // 'Analysis.Doublet.Detection.And.Removal': 'Doublet detection and removal',
    'Analysis.Batch.Effect.Removal': '批次效应移除',

    'Analysis.Analysis.Options': 'Analysis options',
    'Analysis.Clustering.Resolution': '聚类分辨率',
    'Analysis.Number.Of.Pcs': 'Number of pcs',
    'Analysis.Removal.Cell.Cycle.Effect': 'Remove cell-cycle effects',
    'Analysis.UMAP.Min.Distance': 'UMAP.min_distance',
    'Analysis.Scale.Large.Data': 'Scale large data',
    'Analysis.Run.tSNE.For.Large.Data': 'Run tSNE for large data',

    'Analysis.Annotation.Options': 'Annotation options',
    'Analysis.Include.Inferred.Cluster': 'Include Inferred clusters',
    'Analysis.Choose.Algorithm': 'Choose algorithm',
    'Analysis.Choose.Reference': 'Choose reference',
    'Analysis.Reference.Tissue': 'Reference tissue',

    'Analysis.Sub.Clustering': 'Subclustering',
    'Analysis.Subclustering.With.Annotation': '自动注释',
    'Analysis.Sub.Clustering.Only': '机械分群',
    'Analysis.Annotation.Algorithm': 'Annotation algorithm',
    'Analysis.Annotation.Reference': 'Annotation Reference',

    // new add
    'Analysis.Annotation.Type': '注释类型',
    'Analysis.Annotation.Algorithm1': '注释算法',
    'Analysis.Annotation.Reference1': '注释参考',
    'Analysis.Annotation.Species': '物种',
    'Analysis.Annotation.Reference.Tissue': '参考组织',
    'Analysis.Mitochondrial.Content': '线粒体含量',
    'Analysis.Cell.Quality.Ambient': '背景噪音去除',
    'Analysis.Cell.Quality.Doublet.DR': '双胞检测和移除',
    'Analysis.Number.Of.Hvg': '高可变基因数量',
    'Analysis.Sub.Clusters': 'Clusters',
    'Analysis.Sub.Samples': '样本',
    'Analysis.Cell.Type.Reference': '细胞类型参考',
    'Analysis.Subset': '子集',
};

export const analysisInfoUS = {
    'Analysis.Tab.Name': 'Analysis info.',
    'Analysis.Analysis.ID': 'Analysis ID',
    'Analysis.Cells': 'Cells',
    'Analysis.Samples': 'Samples',

    'Analysis.Sample.Info': 'Sample info',
    'Analysis.Sample.Counts': 'Sample counts',
    'Analysis.Sample.Names': 'Sample names',
    'Analysis.Species': 'Species',
    'Analysis.Tissue': 'Tissue',
    'Analysis.Sample.Diseases': 'Sample diseases',

    'Analysis.Cell.Filtering.Info': 'Cell filtering info',
    'Analysis.Expressed.Gene.Range': 'Expressed gene range',
    'Analysis.Expressed.UMI.Range': 'Expressed UMI range',
    'Analysis.Mitochondrial.Content.Below': 'Mitochondrial content below(percentage)',

    'Analysis.Cell.Quality.Adjustments': 'Cell quality adjustments',
    'Analysis.Contamination.Removal': 'Contamination removal',
    // 'Analysis.Doublet.Detection.And.Removal': 'Doublet detection and removal',
    'Analysis.Batch.Effect.Removal': 'Batch effect removal',

    'Analysis.Analysis.Options': 'Analysis options',
    'Analysis.Clustering.Resolution': 'Clustering resolution',
    'Analysis.Number.Of.Pcs': 'Number of pcs',
    'Analysis.Removal.Cell.Cycle.Effect': 'Remove cell-cycle effects',
    'Analysis.UMAP.Min.Distance': 'UMAP.min_distance',
    'Analysis.Scale.Large.Data': 'Scale large data',
    'Analysis.Run.tSNE.For.Large.Data': 'Run tSNE for large data',

    'Analysis.Annotation.Options': 'Annotation options',
    'Analysis.Include.Inferred.Cluster': 'Include Inferred clusters',
    'Analysis.Choose.Algorithm': 'Choose algorithm',
    'Analysis.Choose.Reference': 'Choose reference',
    'Analysis.Reference.Tissue': 'Reference tissue',

    'Analysis.Sub.Clustering': 'Subclustering',
    'Analysis.Subclustering.With.Annotation': 'Subclustering with annotation',
    'Analysis.Sub.Clustering.Only': 'Subclustering only',
    'Analysis.Annotation.Algorithm': 'Annotation algorithm',
    'Analysis.Annotation.Reference': 'Annotation Reference',

    // new add
    'Analysis.Annotation.Type': 'Annotation type',
    'Analysis.Annotation.Algorithm1': 'Algorithm',
    'Analysis.Annotation.Reference1': 'Reference',
    'Analysis.Annotation.Species': 'Species',
    'Analysis.Annotation.Reference.Tissue': 'Reference tissue',
    'Analysis.Mitochondrial.Content': 'Mitochondrial content',
    'Analysis.Cell.Quality.Ambient': 'Ambient RNA removal',
    'Analysis.Cell.Quality.Doublet.DR': 'Doublet detection and removal',
    'Analysis.Number.Of.Hvg': 'Number of highly variable genes',
    'Analysis.Sub.Clusters': 'Clusters',
    'Analysis.Sub.Samples': 'Samples',
    'Analysis.Cell.Type.Reference': 'Cell type reference',
    'Analysis.Subset': 'Subset',
};