export const umapCN = {
    'Umap.Tabs.Name': '数据分析',
    'Umap.Tabs.Cell.Type': '子集',
    'Umap.Tabs.Sample': '样本',
    'Umap.Tabs.Sample.Group': '样本分组',
    'Umap.Select.All': '选择所有',
    'Umap.View': '可视化',
    'Umap.Task': '任务',
    'Umap.Subset': '子集',
    'Umap.color.By': '着色',
    'Umap.Scatter.Size': '点大小',
    'Umap.Opacity': '透明度',
    'Umap.X.Axis': 'x轴',
    'Umap.Y.Axis': 'y轴',
    'Umap.On.Cluster': 'Cluster调整',
    'Umap.Label.On.Side': '标记在旁边',
    'Umap.Label.On.Cluster': '标记在图上',
    'Umap.Change.Color.Tips': '点击色块更改Cluster颜色',
    'Umap.Standard.Publication.Colors': '目前展示颜色',
    'Umap.Choose.More.Colors': '更改颜色',
    'Umap.Change.Color.Default': ' 默认',
    'Umap.Change.Color.Customize': '自定义',
    'Umap.Color.Palettes': '颜色色板',

    'Umap.Click.To.Download.Image': ' 点击下载图片',
    'Umap.Selection.Of.Color.Schema': '选择配色方案',

    'Image.Scale.Zoom': 'Zoom',
    'Violin.Plot.Data.Point': 'Data point',

    'Group.Add.Group': '新增样本分组',
    'Group.New.Metadata.Name': '新分组名称',
    'Group.In.This.Metadata': '组份',
    'Group.Add.Groups.In.Metadata': '添加新组份',
    'Group.No.Sample.Selection': 'No more samples available for selection.',
    'Group.Name.Exists': 'Name exists, please type another.',
    'Group.Delete.Message.Box': '该样本分组删除后无法恢复',
    'Umap.Legend.Font.Size': '图例字体大小',
};

export const umapUS = {
    'Umap.Tabs.Name': 'Data and analysis',
    'Umap.Tabs.Cell.Type': 'Subset',
    'Umap.Tabs.Sample': 'Sample',
    'Umap.Tabs.Sample.Group': 'Sample group',
    'Umap.Select.All': 'Select all',
    'Umap.View': 'View',
    'Umap.Task': 'Task',
    'Umap.Subset': 'Subset',
    'Umap.color.By': 'Color by',
    'Umap.Scatter.Size': 'Scatter size',
    'Umap.Opacity': 'Opacity',
    'Umap.X.Axis': 'X-axis',
    'Umap.Y.Axis': 'Y-axis',
    'Umap.On.Cluster': 'On cluster',
    'Umap.Label.On.Side': 'Label on side',
    'Umap.Label.On.Cluster': 'Label on cluster',
    'Umap.Change.Color.Tips': 'Click on color blocks to change cluster color',
    'Umap.Standard.Publication.Colors': 'Standard Publication colors',
    'Umap.Choose.More.Colors': 'Choose more colors',
    'Umap.Change.Color.Default': 'Default',
    'Umap.Change.Color.Customize': 'customize',
    'Umap.Color.Palettes': 'Color palettes',
    'Umap.Click.To.Download.Image': 'Click to download image',
    'Umap.Selection.Of.Color.Schema': 'Selection of color schemes',

    'Image.Scale.Zoom': 'Zoom',
    'Violin.Plot.Data.Point': 'Data point',

    'Group.Add.Group': 'Add a new sample group ',
    'Group.New.Metadata.Name': 'New metadata name',
    'Group.In.This.Metadata': 'Groups in this metadata',
    'Group.Add.Groups.In.Metadata': 'Add groups in this metadata',
    'Group.No.Sample.Selection': 'No more samples available for selection.',
    'Group.Name.Exists': 'Name exists, please type another.',
    'Group.Delete.Message.Box': 'This sample group will be deleted permanently.',

    'Umap.Legend.Font.Size': 'Legend font size',
};